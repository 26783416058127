import React, { useRef, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa6'
import { IoIosCloseCircle, IoIosSave } from 'react-icons/io'

import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik'
import * as yup from 'yup'

import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react'

import Select from '@/components/Select'
import StatusTag from '@/components/Tag/StatusQuestions'

import { TQuestionDetailsFormValues } from './types'

const QuestionDetails: React.FC = () => {
  const [activeQuestion, setActiveQuestion] = useState(false)

  const formikRef = useRef<FormikProps<TQuestionDetailsFormValues>>(null)

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório'),
    formatVolume: yup
      .object({
        value: yup.string(),
      })
      .required('O formato é obrigatório'),
    versãoVolume: yup
      .object({
        value: yup.string(),
      })
      .required('A versão é obrigatória'),
    exemplaryVolume: yup
      .object({
        value: yup.string(),
      })
      .required('O exemplar é obrigatório'),
    diligence: yup
      .object({
        value: yup.string(),
      })
      .required('A diligência é obrigatória'),
    typeQuestion: yup
      .object({
        value: yup.string(),
      })
      .required('O tipo é obrigatório'),
  })

  const onSubmit = (values: TQuestionDetailsFormValues) => {
    const payload = {
      title: values.title,
      formatVolume: values.formatVolume,
      versionVolume: values.versionVolume,
      exemplaryVolume: values.exemplaryVolume,
      diligence: values.diligence,
      typeQuestion: values.typeQuestion,
    }
  }

  return (
    <>
      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between" align="center">
          <Box>
            <Text
              fontSize="xl"
              fontWeight="bold"
              color="brand.primary.dark_1"
              mb={2}
            >
              ID DA PERGUNTA
            </Text>
            <StatusTag tag="active" />
          </Box>
          <Flex align="center">
            <FormLabel fontSize="sm" mb={1}>
              {activeQuestion ? 'Desativar pergunta' : 'Ativar pergunta'}
            </FormLabel>
            <Switch
              size="md"
              onChange={() => setActiveQuestion(!activeQuestion ?? false)}
              sx={{
                '& .chakra-switch__track[data-checked]': {
                  backgroundColor: 'brand.primary.dark_1',
                },
              }}
            />
          </Flex>
        </Flex>
        <Formik
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          initialValues={{
            title: '',
            formatVolume: '',
            versionVolume: '',
            exemplaryVolume: '',
            diligence: '',
            typeQuestion: '',
          }}
        >
          <Form>
            <Stack gap={4} marginTop={4}>
              <FormControl>
                <FormLabel fontSize="sm">Título da pergunta</FormLabel>
                <Field
                  as={Input}
                  type="name"
                  name="title"
                  placeholder="---"
                  variant="filled"
                />
                <ErrorMessage name="title">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>

              <Flex
                gap="4"
                flexDirection={{
                  base: 'column',
                  md: 'row',
                  xl: 'row',
                }}
              >
                <FormControl>
                  <FormLabel fontSize="sm">Formato do volume</FormLabel>
                  <Field name="formatVolume">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="---"
                        isSearchable={false}
                        options={[
                          { value: 'option1', label: 'Opção 1' },
                          { value: 'option2', label: 'Opção 2' },
                          { value: 'option3', label: 'Opção 3' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="formatVolume">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Versão do volume</FormLabel>
                  <Field name="versionVolume">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="---"
                        isSearchable={false}
                        options={[
                          { value: 'option1', label: 'Opção 1' },
                          { value: 'option2', label: 'Opção 2' },
                          { value: 'option3', label: 'Opção 3' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="versionVolume">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Exemplar do volume</FormLabel>
                  <Field name="exemplaryVolume">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="---"
                        isSearchable={false}
                        options={[
                          { value: 'option1', label: 'Opção 1' },
                          { value: 'option2', label: 'Opção 2' },
                          { value: 'option3', label: 'Opção 3' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="exemplaryVolume">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Flex>

              <Flex
                direction="row"
                gap="4"
                flexDirection={{
                  base: 'column',
                  md: 'row',
                  xl: 'row',
                }}
              >
                <FormControl>
                  <FormLabel fontSize="sm">Diligência</FormLabel>
                  <Field name="diligence">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="---"
                        isSearchable={false}
                        options={[
                          { value: 'option1', label: 'Opção 1' },
                          { value: 'option2', label: 'Opção 2' },
                          { value: 'option3', label: 'Opção 3' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="diligence">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="sm">Tipo de resposta</FormLabel>
                  <Field name="typeQuestion">
                    {({ field, form }: FieldProps) => (
                      <Select
                        value={field.value}
                        variant="filled"
                        placeholder="---"
                        isSearchable={false}
                        options={[
                          { value: 'option1', label: 'Opção 1' },
                          { value: 'option2', label: 'Opção 2' },
                          { value: 'option3', label: 'Opção 3' },
                        ]}
                        onChange={option => {
                          form.setFieldValue(field.name, option)
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="typeQuestion">
                    {(message: string) => (
                      <Text color="red.600" fontSize="sm">
                        {message}
                      </Text>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Flex>
            </Stack>
          </Form>
        </Formik>
      </Stack>
      <Stack p={4} boxShadow="md" borderRadius="md">
        <Flex justify="space-between">
          <Button
            size="sm"
            key="go-back"
            variant="ghost"
            leftIcon={<FaChevronLeft />}
            color="brand.primary.dark_1"
            // onClick={}
          >
            Voltar
          </Button>
          <Box>
            <Button
              leftIcon={<IoIosCloseCircle size="16px" />}
              size="sm"
              backgroundColor="brand.error.base"
              marginRight={4}
              _hover={{ bg: 'brand.error.dark' }}
              // onClick={}
            >
              Excluir equipes
            </Button>
            <Button
              size="sm"
              leftIcon={<IoIosSave size="16px" />}
              // onClick={}
            >
              Salvar alterações
            </Button>
          </Box>
        </Flex>
      </Stack>
    </>
  )
}

export default QuestionDetails
