import { FaHome, FaBook } from 'react-icons/fa'
import { FaFileAlt, FaClipboardList } from 'react-icons/fa'
import { FaFolderOpen } from 'react-icons/fa6'
import { useLocation } from 'react-router-dom'

import {
  TTemplateSidebarProps,
  TTemplateBreadcrumbProps,
  TTemplateBreadcrumbStructure,
} from './types'

export const sidebarProps = {
  linkItems: [
    {
      name: 'Painel',
      icon: FaHome,
      to: '/painel',
    },
    {
      name: 'Objetos',
      icon: FaFolderOpen,
      to: '/objetos',
    },
    {
      name: 'Volumes',
      icon: FaBook,
      to: '/obras',
    },
    {
      name: 'Diligências',
      icon: FaFileAlt,
      to: '/diligencias',
    },
    {
      name: 'Formulários',
      icon: FaClipboardList,
      to: '/formularios',
    },
  ],
} as TTemplateSidebarProps

export const useBreadcrumbProps = (): TTemplateBreadcrumbProps => {
  const location = useLocation()

  const page = location.pathname
  const pagePath = page.split('/').filter(item => item !== '')

  const breadcrumbStructure = {
    painel: {
      info: {
        label: 'Painel',
        href: '/',
      },
    },
    gerenciamento: {
      info: {
        label: 'Gerenciamento',
        href: '/gerenciamento',
      },
      perfis: {
        info: {
          label: 'Perfis',
          href: '/gerenciamento/perfis',
        },
        criar: {
          info: {
            label: 'Criar',
            href: '/gerenciamento/perfis/criar',
          },
        },
        editar: {
          info: {
            label: 'Editar',
            href: '/gerenciamento/perfis/editar',
          },
        },
        visualizar: {
          info: {
            label: 'Visualizar',
            href: '/gerenciamento/perfis/visualizar',
          },
        },
      },
    },
    perfil: {
      info: {
        label: 'Perfil',
        href: '/perfil',
      },
      'informacoes-pessoais': {
        info: {
          label: 'Informações Pessoais',
          href: '/perfil/informacoes-pessoais',
        },
      },
      vinculos: {
        info: {
          label: 'Vínculos',
          href: '/perfil/vinculos',
        },
      },
    },
    configuracoes: {
      info: {
        label: 'Configurações do Sistema',
        href: '/configuracoes',
      },
      usuario: {
        info: {
          label: 'Usuários',
          href: '/configuracoes/usuario',
        },
        detalhes: {
          info: {
            label: 'Detalhes',
            href: '/configuracoes/usuario/detalhes',
          },
        },
      },
      equipe: {
        info: {
          label: 'Equipes',
          href: '/configuracoes/equipe',
        },
        detalhes: {
          info: {
            label: 'Detalhes de Equipe',
            href: '/configuracoes/equipe/detalhes',
          },
        },
      },
      permissoes: {
        info: {
          label: 'Permissões',
          href: '/configuracoes/permissoes',
        },
        detalhes: {
          info: {
            label: 'Detalhes',
            href: '/configuracoes/permissoes/detalhes',
          },
        },
      },
    },
    objetos: {
      info: {
        label: 'Objetos',
        href: '/objetos',
      },
      'dimensionamento-volumes': {
        info: {
          label: 'Dimensionamento de Volumes',
          href: '/objetos/dimensionamento-volumes',
        },
      },
      'distribuicao-times': {
        info: {
          label: 'Distribuição de Times',
          href: '/objetos/distribuicao-times',
        },
      },
      'redistribuicao-times': {
        info: {
          label: 'Redistribuição de Times',
          href: '/objetos/redistribuicao-times',
        },
        'por-volume': {
          info: {
            label: 'Por Volume',
            href: '/objetos/redistribuicao-times/por-volume',
          },
        },
        'por-membro': {
          info: {
            label: 'Por Membro',
            href: '/objetos/redistribuicao-times/por-membro',
          },
        },
      },
    },
    formularios: {
      info: {
        label: 'Formulários',
        href: '/formularios',
      },
      'cadastrar-grupo-de-falhas': {
        info: {
          label: 'Cadastro de falhas',
          href: '/formularios/cadastrar-grupo-de-falhas',
        },
      },
      'detalhes-da-pergunta': {
        info: {
          label: 'Detalhes da pergunta',
          href: '/formularios/detalhes-da-pergunta',
        },
      },
    },
  } as TTemplateBreadcrumbStructure

  const breadcrumbItems = []

  let currentBreadcrumbStructure = breadcrumbStructure
  for (const currentPath of pagePath) {
    if (currentBreadcrumbStructure[currentPath]) {
      breadcrumbItems.push(currentBreadcrumbStructure[currentPath].info)

      const { info, ...breadcrumbChildren } =
        currentBreadcrumbStructure[currentPath]
      currentBreadcrumbStructure = breadcrumbChildren
    }
  }

  return {
    items: breadcrumbItems,
  }
}
