import React, { useRef } from 'react'

import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikProps,
} from 'formik'
import * as yup from 'yup'

import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'

import Modal from '@/components/Modal'
import Select from '@/components/Select'

import S from './styles'
import {
  IRegisterQuestionModalProps,
  TRegisterQuestionFormValues,
} from './types'

const RegisterQuestionModal: React.FC<IRegisterQuestionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const formikRef = useRef<FormikProps<TRegisterQuestionFormValues>>(null)

  const validationSchema = yup.object({
    title: yup.string().required('O título é obrigatório'),
    formatVolume: yup
      .object({
        value: yup.string(),
      })
      .required('O formato é obrigatório'),
    versãoVolume: yup
      .object({
        value: yup.string(),
      })
      .required('A versão é obrigatória'),
    exemplaryVolume: yup
      .object({
        value: yup.string(),
      })
      .required('O exemplar é obrigatório'),
    codeFail: yup
      .object({
        value: yup.string(),
      })
      .required('O código da falha é obrigatório'),
    typeQuestion: yup
      .object({
        value: yup.string(),
      })
      .required('O tipo é obrigatório'),
    showPages: yup
      .object({
        value: yup.string(),
      })
      .required('Selecione uma opção'),
    description: yup.string().required('A descrição é obrigatória'),
  })

  const onSubmit = (values: TRegisterQuestionFormValues) => {
    const payload = {
      title: values.title,
      formatVolume: values.formatVolume,
      versionVolume: values.versionVolume,
      exemplaryVolume: values.exemplaryVolume,
      codeFail: values.codeFail,
      typeQuestion: values.typeQuestion,
      showPages: values.showPages,
      description: values.description,
    }
  }

  const handleRegisterQuestion = () => {
    if (formikRef.current) {
      formikRef.current.validateForm().then(errors => {
        if (Object.keys(errors).length === 0) {
          formikRef.current && formikRef.current.handleSubmit()
          onClose()
        }
      })
    }
  }

  return (
    <Modal
      title="Cadastrar nova pergunta"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{
        base: 'sm',
        sm: 'sm',
        md: '3xl',
        xl: '4xl',
      }}
    >
      <Formik
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        initialValues={{
          title: '',
          formatVolume: '',
          versionVolume: '',
          exemplaryVolume: '',
          codeFail: '',
          typeQuestion: '',
          showPages: false,
          description: '',
        }}
      >
        <Form>
          <Stack gap={4}>
            <FormControl>
              <FormLabel fontSize="sm">Título da pergunta</FormLabel>
              <Field
                as={Input}
                type="name"
                name="title"
                placeholder="---"
                variant="filled"
              />
              <ErrorMessage name="title">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>

            <Flex
              gap="4"
              flexDirection={{
                base: 'column',
                md: 'row',
                xl: 'row',
              }}
            >
              <FormControl>
                <FormLabel fontSize="sm">Formato do volume</FormLabel>
                <Field name="formatVolume">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: 'option1', label: 'Opção 1' },
                        { value: 'option2', label: 'Opção 2' },
                        { value: 'option3', label: 'Opção 3' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="formatVolume">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Versão do volume</FormLabel>
                <Field name="versionVolume">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: 'option1', label: 'Opção 1' },
                        { value: 'option2', label: 'Opção 2' },
                        { value: 'option3', label: 'Opção 3' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="versionVolume">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Exemplar do volume</FormLabel>
                <Field name="exemplaryVolume">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: 'option1', label: 'Opção 1' },
                        { value: 'option2', label: 'Opção 2' },
                        { value: 'option3', label: 'Opção 3' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="exemplaryVolume">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
            </Flex>

            <Flex
              direction="row"
              gap="4"
              flexDirection={{
                base: 'column',
                md: 'row',
                xl: 'row',
              }}
            >
              <FormControl>
                <FormLabel fontSize="sm">Código da falha</FormLabel>
                <Field name="codeFail">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: 'option1', label: 'Opção 1' },
                        { value: 'option2', label: 'Opção 2' },
                        { value: 'option3', label: 'Opção 3' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="codeFail">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Tipo de resposta</FormLabel>
                <Field name="typeQuestion">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: 'option1', label: 'Opção 1' },
                        { value: 'option2', label: 'Opção 2' },
                        { value: 'option3', label: 'Opção 3' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="typeQuestion">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel fontSize="sm">Informar as páginas?</FormLabel>
                <Field name="showPages">
                  {({ field, form }: FieldProps) => (
                    <Select
                      value={field.value}
                      variant="filled"
                      placeholder="---"
                      isSearchable={false}
                      options={[
                        { value: true, label: 'Sim' },
                        { value: false, label: 'Não' },
                      ]}
                      onChange={option => {
                        form.setFieldValue(field.name, option)
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="showPages">
                  {(message: string) => (
                    <Text color="red.600" fontSize="sm">
                      {message}
                    </Text>
                  )}
                </ErrorMessage>
              </FormControl>
            </Flex>

            <FormControl as={Stack}>
              <FormLabel fontSize="sm">Descrição</FormLabel>
              <Field
                as={Textarea}
                type="text"
                name="description"
                placeholder="Descrição"
                variant="filled"
                maxH="142px"
                resize="none"
              />
              <Text fontSize="sm" color="brand.neutral.dark_1">
                Descreva aqui uma ajuda para o analista responder a pergunta
              </Text>
              <ErrorMessage name="description">
                {(message: string) => (
                  <Text color="red.600" fontSize="sm">
                    {message}
                  </Text>
                )}
              </ErrorMessage>
            </FormControl>
          </Stack>
        </Form>
      </Formik>
      <S.ModalActionButtons>
        <Button
          key="go-back"
          variant="outline"
          size="sm"
          onClick={onClose}
          borderRadius={50}
        >
          Cancelar
        </Button>
        <Button
          key="confirm"
          size="sm"
          bg="brand.primary.dark_1"
          color="white"
          _hover={{
            bg: 'brand.primary.dark_2',
          }}
          onClick={handleRegisterQuestion}
          borderRadius={50}
        >
          Cadastrar
        </Button>
      </S.ModalActionButtons>
    </Modal>
  )
}

export default RegisterQuestionModal
