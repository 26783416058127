import React, { useState } from 'react'
import { FaPlusCircle, FaRegEdit } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import {
  Stack,
  Text,
  Flex,
  Button,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'

import DataFilter, { IFilter } from '@/components/DataFilter'
import DataTable from '@/components/DataTable'
import { IHeader } from '@/components/DataTable/types'
import Pagination from '@/components/Pagination'
import { IPageInfoProps } from '@/components/Pagination/types'
import StatusTag from '@/components/Tag/StatusQuestions'

import RegisterQuestionModal from './parts/RegisterQuestionModal'

interface IInfoProps {
  title: string
  subtitle: string
}

const Info = ({ title, subtitle }: IInfoProps) => {
  return (
    <Stack gap={0.5} color="brand.neutral.dark_2">
      <Text fontSize="sm">{title}</Text>
      <Text fontSize="xs">{subtitle}</Text>
    </Stack>
  )
}

const Actions = () => {
  const navigate = useNavigate()

  return (
    <IconButton
      variant="ghost"
      aria-label="Editar"
      icon={<FaRegEdit />}
      onClick={() => navigate('/formularios/detalhes-da-pergunta')}
    />
  )
}

const QuestionsList = () => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    itemsCount: 20,
    pageCount: Math.ceil(20 / 10),
    pageSizeOptions: [10, 20, 30, 40, 50],
  })

  const tableHeaders: Array<IHeader> = [
    {
      name: 'Pergunta',
      key: 'question',
      type: 'element',
      width: '35%',
    },
    {
      name: 'Tipo de Formulário',
      key: 'form_type',
      type: 'element',
      width: '20%',
    },
    {
      name: 'Tipo de Resposta',
      key: 'answer_type',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Status',
      key: 'status',
      type: 'element',
      width: '20%',
      align: 'center',
    },
    {
      name: 'Ações',
      key: 'actions',
      type: 'element',
      width: '5%',
      align: 'center',
    },
  ]

  const tableData = [
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Aberta',
      status: StatusTag({ tag: 'active' }),
      actions: <Actions />,
    },
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Aberta',
      status: StatusTag({ tag: 'active' }),
      actions: <Actions />,
    },
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Sim/Não',
      status: StatusTag({ tag: 'inactive' }),
      actions: <Actions />,
    },
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Múltipla escolha',
      status: StatusTag({ tag: 'active' }),
      actions: <Actions />,
    },
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Sim/Não',
      status: StatusTag({ tag: 'inactive' }),
      actions: <Actions />,
    },
    {
      question: <Info title="Nome da pergunta" subtitle="ID PERGUNTA" />,
      form_type: 'Tipo de formulário',
      answer_type: 'Múltipla escolha',
      status: StatusTag({ tag: 'active' }),
      actions: <Actions />,
    },
  ]

  const filters: Array<IFilter> = [
    {
      name: 'answer_type',
      label: 'Tipo de resposta',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'form_type',
      label: 'Formato do volume',
      type: 'select',
      options: [
        { label: 'Option 1', value: 'option1' },
        { label: 'Option 2', value: 'option2' },
      ],
    },
    {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { label: 'active', value: 'Ativa' },
        { label: 'inactive', value: 'Inativa' },
      ],
    },
  ]

  const handleOnChangePageInfo = ({
    pageIndex,
    itemsPerPage,
  }: IPageInfoProps) => {
    const previousItemsPerPage = pagination.pageSize
    const adjustedPageIndex =
      itemsPerPage !== previousItemsPerPage ? 1 : pageIndex + 1

    setPagination({
      ...pagination,
      page: adjustedPageIndex,
      pageSize: itemsPerPage,
      pageCount: Math.ceil(10 / itemsPerPage),
    })
  }

  const {
    isOpen: isRegisterQuestionOpen,
    onOpen: onRegisterQuestionOpen,
    onClose: onRegisterQuestionClose,
  } = useDisclosure()

  const handleOpenRegisterFailModal = () => {
    onRegisterQuestionOpen()
  }

  return (
    <Stack p={4} spacing={8} boxShadow="md" borderRadius="md">
      <RegisterQuestionModal
        isOpen={isRegisterQuestionOpen}
        onClose={onRegisterQuestionClose}
      />
      <Flex justify="space-between" align="center">
        <Text fontSize="xl" fontWeight="bold" color="brand.primary.dark_1">
          Lista de perguntas
        </Text>
        <Button
          leftIcon={<FaPlusCircle />}
          borderRadius="6px"
          size="sm"
          onClick={() => handleOpenRegisterFailModal()}
        >
          Cadastrar nova pergunta
        </Button>
      </Flex>
      <DataFilter
        testId="data-filter"
        canDownload={false}
        canPrint={false}
        onlySearch
        filters={filters}
        onChangeFilter={() => {}}
      />
      <DataTable headers={tableHeaders} data={tableData} />
      <Pagination
        pageIndex={pagination.page - 1}
        optionsItemsPerPage={pagination.pageSizeOptions}
        itemsPerPage={pagination.pageSize}
        pageCount={pagination.pageCount}
        itemsCount={pagination.itemsCount}
        onChangePageInfo={handleOnChangePageInfo}
      />
    </Stack>
  )
}

export default QuestionsList
